import type { FirebaseApp } from 'firebase/app';
import { initializeApp } from 'firebase/app';
import type { Messaging } from 'firebase/messaging';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';

const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyBsQOMMi0bD-GJCaaZnXf8P_CjFTjJQoCg',
  authDomain: 'sex-play.firebaseapp.com',
  projectId: 'sex-play',
  storageBucket: 'sex-play.appspot.com',
  messagingSenderId: '937494626740',
  appId: '1:937494626740:web:235ba50b0201db9c9c32e1',
  measurementId: 'G-XV8YPN7D1Y',
};

const VAPID_KEY =
  'BF1i_lwYxV5mpmaGBCoeD5Fuj2DRT5ek2zHR3-YUf14yc45ZI_r7daQo_-D5gc9AkYHpHZSlM8lTaKB6JO7drjg';

const SW_SCRIPT = '/firebase-messaging-sw.js';
const SW_SCOPE = '/firebase-cloud-messaging-push-scope';

export const useFirebaseStore = defineStore('firebase', () => {
  const mainStore = useMainStore();

  const app = shallowRef({} as FirebaseApp);
  const messaging = shallowRef({} as Messaging);
  const token = ref('');
  const supported = ref(false);

  const registerSW = (swScript: string, scope: string) =>
    navigator.serviceWorker.register(swScript, { scope }).then((request) => {
      const incoming = request.installing || request.waiting;

      if (request.active && !incoming) return Promise.resolve();

      if (!incoming) return Promise.reject();

      return new Promise((resolve) => {
        incoming.onstatechange = (event) => {
          if ((event.target as ServiceWorker).state === 'activated') {
            incoming.onstatechange = null;
            return resolve(null);
          }
        };
      });
    });

  const update = async (value?: boolean) => {
    if (!supported.value) return Promise.reject();

    await registerSW(SW_SCRIPT, SW_SCOPE);

    return getToken(messaging.value, { vapidKey: VAPID_KEY }).then(
      (currentToken) => {
        if (currentToken) {
          token.value = currentToken;
          UserAPI.updateNotification({
            body: { token: currentToken, value },
          }).then((data) => {
            mainStore.notification =
              Notification.permission === 'granted' &&
              data.some((el) => el.token === token.value && el.value);
          });
        } else {
          return Promise.reject();
        }
      },
    );
  };

  const request = async () => {
    if (
      mainStore.loaded &&
      supported.value &&
      'Notification' in window &&
      Notification.permission === 'granted'
    )
      return update();
  };

  const init = async () => {
    const value = await isSupported();
    supported.value = value;

    if (!value) return;

    app.value = initializeApp(FIREBASE_CONFIG);
    messaging.value = getMessaging(app.value);

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((data) => {
        data.forEach((el) => {
          if (el.active?.scriptURL.endsWith(SW_SCRIPT)) el.update();
        });
      });
    }

    await request();
  };

  return {
    token,
    supported,

    init,
    update,
    request,
  };
});
